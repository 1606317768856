import {post, put, get} from '../http'

const scfBusinessApi = {

 //字典信息查询
	findDicLtApi(params) {
		return post('/business/dictionary/findDicLt', params, true, 5000, "apc");
	}, //文件上传
	fileUploadApi(params) {
		return post('/business/resourcefile/upload', params, true, 5000, "apc");
	}, //下载文件流
	fileDownloadApi(params) {
		return post('/business/resourcefile/downloadFileToBase64', params, true, 50000, "apc");
	}, //资源文件删除
	fileDeleteApi(params) {
		return post('/business/resourcefile/delete', params, true, 5000, "apc");
	}, //资源文件删除
	resourceFiledeleteSApi(params) {
		return post('/business/resourcefile/delete', params);
	}, //公用接口-资源文件上传
	resourceFileUploadApi(params) {
		return post('/business/resourcefile/upload', params);
	}, 

	//字典查询批量
	dictionaryApi(params) {

		return post(`/business/dictionary?typeList=${params.typeList[0]},adsf`, params);
	},

	//待办进入所需接口参数IDS查询
	getAuditNeedIdsApi(params) {
		return post(`/business/split/transfer/getAuditNeedIds`, params);
	},
	 //首页待办事项查询
	todoQueryApi(params) {
		return post(`/business/audit/todoQuery`, params);
	}, 

	//首页-经办事项
	doneQueryApi(params) {
		return post(`/business/audit/doneQuery`, params);
	},

	// 出票信息列表
	getTicketDrawListApi(params) {
		return post(`/scb/v1/issue/register/list`, params);
	}, // 承兑应答列表
	getIssueAcceptListApi(params) {
		return post(`/scb/v1/issue/accept/reply/list`, params);
	}, // 收票应答列表
	getIssueReceiptListApi(params) {
		return post(`/scb/v1/issue/receipt/reply/list`, params);
	}, // 出票数据查询列表
	getDrawListApi(params) {
		return post(`/scb/v1/issue/list`, params);
	}, // 首页-待收票信息列表
	getReceivedListApi(params) {
		return post(`/scb/v1/issue/to/be/received/list`, params);
	}, // 出票信息新增
	addTicketDrawerApi(params) {
		return post(`/scb/v1/issue/add`, params);
	}, // 出票信息详情
	getTicketDrawerDetailApi(params) {
		return post(`/scb/v1/issue/getDetail`, params);
	}, // 出票信息编辑
	updateTicketDrawerApi(params) {
		return post(`/scb/v1/issue/edit`, params);
	}, // 合同编号生成
	generateContractNumApi(params) {
		return post(`/scb/v1/issue/contract/no/generate`, params);
	}, // 上传发票-发票批量解析
	analysisInvoiceApi(params) {
		return post(`/scb/v1/issue/invoice/batch/parse`, params);
	}, // 上传发票-发票批量新增
	addInvoiceApi(params) {
		return post(`/scb/v1/issue/invoice/batch/add`, params);
	}, // 获取发票池列表
	getInvociePoolListApi(params) {
		return post(`/scb/v1/issue/invoice/pool/list`, params);
	}, // 发票金额统计(已存入发票池中的发票统计)
	getInvoiceStatisticsApi(params) {
		return post(`/scb/v1/issue/invoice/pool/statistics`, params);
	}, // 金额统计（发票上传页面解析出的数据）
	getInvoiceAmountApi(params) {
		return post(`/scb/v1/issue/amount/statistics`, params);
	}, // 上传贸易背景/重新提交贸易背景
	uploadTradeBgDrawApi(params) {
		return post(`/scb/v1/issue/trade/background/upload`, params);
	}, // 票据合同信息
	queryContractApi(params) {
		return post(`/scb/v1/issue/contract/query`, params);
	}, // 票据发票信息列表
	getInvoiceDetailApi(params) {
		return post(`/scb/v1/issue/invoice/list`, params);
	}, // 核对信息
	checkDrawInformationApi(params) {
		return post(`/scb/v1/issue/info/check`, params);
	}, // 出票申请审核
	auditDrawApplyApi(params) {
		return post(`/scb/v1/issue/apply/audit`, params);
	}, // 贸易背景核对
	checkTradeBgInformationApi(params) {
		return post(`/scb/v1/issue/trade/background/check`, params);
	}, // 出票贸易背景审核
	auditTradeBgApi(params) {
		return post(`/business/v1/ticket/trade/background/audit`, params);
	}, // 票面凭证查询
	queryVoucherApi(params) {
		return post(`/scb/v1/issue/ticket/voucher/query`, params);
	}, // 提示承兑申请
	applyPreAcceptApi(params) {
		return post(`/scb/v1/issue/prompt/accept/apply`, params);
	}, // 提示承兑申请审核
	auditPreAcceptApplyApi(params) {
		return post(`/scb/v1/issue/prompt/accept/apply/audit`, params);
	}, // 承兑应答申请
	applyAcceptRespApi(params) {
		return post(`/scb/v1/issue/accept/response/apply`, params);
	}, // 承兑应答申请审核
	auditAcceptRespApplyApi(params) {
		return post(`/scb/v1/issue/accept/response/apply/audit`, params);
	}, // 提示收票申请
	applyPreReceiptApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/apply`, params);
	}, // 提示收票申请审核
	auditPreReceiptApplyApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/apply/audit`, params);
	}, // 收票应答申请
	applyReceiptRespApi(params) {
		return post(`/scb/v1/issue/receipt/response/apply`, params);
	}, // 收票应答申请审核
	auditReceiptRespApplyApi(params) {
		return post(`/scb/v1/issue/receipt/response/apply/audit`, params);
	},

	// 贴现列表查询接口
	getDiscountListApi(params) {
		return post(`/scb/v1/discount/list`, params);
	}, // 贴现利息试算-贴现
	trialInterestApi(params) {
		return post(`/scb/v1/discount/interest/trial`, params);
	}, // 贴现费用试算接口
	trialFeeApi(params) {
		return post(`/scb/v1/discount/fee/trial`, params);
	}, // 贴现申请-贴现
	discountApplyApi(params) {
		return post(`/scb/v1/discount/apply`, params);
	}, // 贴现申请-编辑
	discountEditApi(params) {
		return post(`/scb/v1/discount/edit`, params);
	}, // 贴现重新申请-贴现
	discountReapplyApi(params) {
		return post(`/scb/v1/discount/reapply`, params);
	}, // 贴现贸易背景上传接口
	uploadTradeBgDiscountApi(params) {
		return post(`/scb/v1/discount/trade/background/upload`, params);
	}, // 贴现详情-贴现
	getDiscountDetailApi(params) {
		return post(`/scb/v1/discount/details`, params);
	}, // 贴现费用详情接口
	getDiscountFeeDetailApi(params) {
		return post(`/scb/v1/discount/fee/details`, params);
	}, // 贴现费用支付状态查询接口
	queryPaymentStatusApi(params) {
		return post(`/scb/v1/discount/fee/payment/status/query`, params);
	}, // 贴现线下确认支付接口
	payDiscountOfflineApi(params) {
		return post(`/scb/v1/discount/offline/confirmation/payment`, params);
	}, // 贴现申请审核接口
	auditDiscountApplyApi(params) {
		return post(`/scb/v1/discount/apply/audit`, params);
	}, // 贴现申请核对接口
	checkDiscountApplyApi(params) {
		return post(`/scb/v1/discount/apply/verification`, params);
	},

	// 贴现渠道列表查询接口
	getDiscountChannelListApi(params) {
		return post(`/scb/v1/discount/channel/list`, params);
	}, // 贴现渠道导出所有接口
	getDiscountChannelExportApi(params) {
		return post(`/scb/v1/discount/channel/export/all`, params);
	}, // 贴现渠道费率设置接口
	getDiscountChannelRateApi(params) {
		return post(`/scb/v1/discount/channel/rate/set`, params);
	}, // 贴现费用列表查询接口
	getDiscountFeeListApi(params) {
		return post(`/scb/v1/discount/fee/list`, params);
	}, // 信批状态查询接口
	getDisclosureQueryApi(params) {
		return post(`/scb/v1/disclosure/status/query`, params);
	}, // 信批详情接口
	getDisclosureDetailsApi(params) {
		return post(`/scb/v1/disclosure/details`, params);
	}, // 信批申请接口
	getDisclosureApplyApi(params) {
		return post(`/scb/v1/disclosure/apply`, params);
	}, // 贴现费用收款确认接口
	getDiscountFeeConfirmApi(params) {
		return post(`/scb/v1/discount/fee/receipt/confirm`, params);
	}, // 贴现费用收款审核接口
	getDiscountFeeAuditApi(params) {
		return post(`/scb/v1/discount/fee/collection/audit`, params);
	}, // 贴现费用详情接口
	getDiscountFeeDetailsApi(params) {
		return post(`/scb/v1/discount/fee/details`, params);
	}, // 信批审核接口
	getDisclosureAuditApi(params) {
		return post(`/scb/v1/disclosure/audit`, params);
	}, //查询工作流是否有配置
	haveConfigApi(params) {
		return post(`/workflow/template/config/have`, params);
	}, //查询贴现机构列表
	getInstitutionsListApi(params) {
		return post(`/scb/v1/institutions/list`, params);
	},

	// 提示承兑撤销申请
	applyPreAcceptUndoApi(params) {
		return post(`/scb/v1/issue/prompt/accept/revoke/apply`, params);
	}, // 提示承兑撤销申请审核
	auditPreAcceptUndoApplyApi(params) {
		return post(`/scb/v1/issue/prompt/accept/revoke/apply/audit`, params);
	}, // 提示收票撤销申请
	applyPreReceiptUndoApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/revoke/apply`, params);
	}, // 提示收票撤销申请审核
	auditPreReceiptUndoApplyApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/revoke/apply/audit`, params);
	}, // 提示付款列表
	getPaymentListApi(params) {
		return post(`/scb/v1/accept/prompt/payment/list`, params);
	}, // 承兑信息详情
	getPaymentDetailApi(params) {
		return post(`/scb/v1/accept/info/getDetail`, params);
	}, // 提示付款申请
	applyPrePayApi(params) {
		return post(`/scb/v1/accept/prompt/payment/apply`, params);
	}, // 提示付款申请审核
	auditPrePayApplyApi(params) {
		return post(`/scb/v1/accept/prompt/payment/apply/audit`, params);
	}, // 提示付款撤销申请
	applyPrePayUndoApi(params) {
		return post(`/scb/v1/accept/prompt/payment/revoke/apply`, params);
	}, // 提示付款申请撤销审核
	auditPrePayApplyUndoApi(params) {
		return post(`/scb/v1/accept/prompt/payment/revoke/apply/audit`, params);
	}, // 付款应答列表
	getPaymentRespListApi(params) {
		return post(`/scb/v1/accept/payment/reply/list`, params);
	}, // 承兑票据信息
	getAcceptTicketDetailApi(params) {
		return post(`/scb/v1/accept/ticket/info/query`, params);
	}, // 付款应答申请
	applyPayRespApi(params) {
		return post(`/scb/v1/accept/payment/reply/apply`, params);
	}, // 付款应答申请审核
	auditPayRespApplyApi(params) {
		return post(`/scb/v1/accept/payment/reply/apply/audit`, params);
	}, // 兑付信息查询列表
	getCashListApi(params) {
		return post(`/scb/v1/accept/list`, params);
	}, // 首页-待付款列表
	getToPayListApi(params) {
		return post(`/scb/v1/accept/to/be/paid/list`, params);
	}, // 首页-待背书签收列表
	getToReplayListApi(params) {
		return post(`/scb/v1/endorsement/to/be/reply/list`, params);
	}, // 首页-待出票保证应答列表
	queryDrawEnsureToReplyListApi(params) {
		return post('/scb/v1/issue/ensure/to/be/reply/list', params, true, 50000, "ap2");
	}, // 首页-待承兑保证应答列表
	queryAcceptEnsureToReplyListApi(params) {
		return post('/scb/v1/accept/ensure/to/be/reply/list', params, true, 50000, "ap2");
	}, // 首页-待背书保证应答列表
	queryEndorseEnsureToReplyListApi(params) {
		return post('/scb/v1/endorse/ensure/to/be/reply/list', params, true, 50000, "ap2");
	},

	// 贴现信息详情-自主支付
	discountAutoDetailApi(params) {
		return post(`/scb/v1/discount/info/auto/detail`, params);
	},
	// 贴现信息详情-拆票支付
	discountSplitDetailApi(params) {
		return post(`/scb/v1/discount/info/split/ticket/detail`, params);
	},
	// 保费详情-自主支付
	insuredAutoDetailApi(params) {
		return post(`/scb/v1/insured/discount/info/auto/detail`, params);
	},
	// 保费详情-拆票支付
	insuredSplitDetailApi(params) {
		return post(`/scb/v1/insured/discount/info/split/ticket/detail`, params);
	},
	// 加保贴现利息试算接口
	insuredApplyTrialApi(params) {
		return post(`/scb/v1/insured/discount/apply/interest/trial`, params);
	},
	// 加保贴现申请接口
	insuredApplyApplyApi(params) {
		return post(`/scb/v1/insured/discount/apply/apply`, params);
	},
	// 编辑贴现信息接口
	insuredApplyEditApi(params) {
		return post(`/scb/v1/insured/discount/apply/edit`, params);
	},
	// 重新贴现申请接口
	insuredApplyReapplyApi(params) {
		return post(`/scb/v1/insured/discount/apply/reapply`, params);
	},
	// 贴现贸易背景上传接口
	insuredApplyUploadApi(params) {
		return post(`/scb/v1/insured/discount/apply/trade/background/upload`, params);
	},
	// 加保贴现信息详情接口
	insuredApplyDetailsApi(params) {
		return post(`/scb/v1/insured/discount/apply/details`, params);
	},
	// 加保贴现申请审核接口
	insuredApplyAuditApi(params) {
		return post(`/scb/v1/insured/discount/apply/apply/audit`, params);
	},
	// 加保贴现申请审核查询接口
	insuredApplyParamApi(params) {
		return post(`/scb/v1/insured/discount/apply/audit/param`, params);
	},
	// 加保贴现申请核对接口
	insuredApplyVerificationApi(params) {
		return post(`/scb/v1/insured/discount/apply/apply/verification`, params);
	},

	// 费用管理列表
	feeListApi(params) {
		return post(`/scb/v1/fee/list`, params);
	},
	// 保费管理列表
	feeInsuredListApi(params) {
		return post(`/scb/v1/fee/insured/list`, params);
	},
	// 费用详情
	feeDetailApi(params) {
		return post(`/scb/v1/fee/detail`, params);
	},
	// 自主支付费用-线下支付
	feeOfflinePaymentApi(params) {
		return post(`/scb/v1/fee/offline/confirmation/payment`, params);
	},
	// 拆票支付费用-确认支付
	feeSplitPaymentApi(params) {
		return post(`/scb/v1/fee/split/ticket/confirmation/payment`, params);
	},
	// 自主支付费用收款确认接口
	feeReceiptConfirmApi(params) {
		return post(`/scb/v1/fee/receipt/confirm`, params);
	},
	// 自主支付费用收款审核接口
	feeCollectionAuditApi(params) {
		return post(`/scb/v1/fee/collection/audit`, params);
	},
	// 拆票支付-收款确认
	feeSplitConfirmApi(params) {
		return post(`/scb/v1/fee/split/ticket/receipt/confirm`, params);
	},
	// 自主支付-保费收款确认接口
	feeAutoConfirmApi(params) {
		return post(`/scb/v1/fee/auto/insured/receipt/confirm`, params);
	},
	// 自主支付-保费收款审核接口
	feeAutoAuditApi(params) {
		return post(`/scb/v1/fee/auto/insured/collection/audit`, params);
	},
	// 拆票支付-保费收款确认接口
	feeSplitInsuredConfirmApi(params) {
		return post(`/scb/v1/fee/split/ticket/insured/receipt/confirm`, params);
	},
	
	// 服务费合同列表
	contractsServiceFeeApi(params) {
		return post(`/scb/v1/contracts/serviceFee/page`, params);
	},
	// 保费合同列表
	contractsGuaranteeFeeApi(params) {
		return post(`/scb/v1/contracts/guarantee/page`, params);
	},
	// 合同下载
	contractsDownloadApi(params) {
		return post(`/scb/v1/contracts/${params}/download`);
	},
	// 关联资源文件查询
	fileRelationQueryApi(params) {
		return post(`/scb/v1/file/relation/query`, params);
	},
	// 详情页合同展示
	contractsDownloadsApi(params,scbTicketPackageId) {
		return post(`/scb/v1/${scbTicketPackageId}/contracts/download`, params);
	},
	// 查询加保背书申请里的票据包信息
	insuredDiscountInfoApi(params) {
		return post(`/scb/v1/insured/discount/info/bill/info`, params);
	},
	// 合同模板文件查询
	queryContractTemplateFileApi(params) {
		return post(`/business/v1/contract/template/file/queryContractTemplateFile`, params);
	},
	// 查询贴现票据包信息
	queryDiscountBillApi(params) {
		return post(`/scb/v1/discount/info/bill/info`, params);
	},
	// 费用详情查询多个费用信息
	feeDetailListApi(params) {
		return post(`/scb/v1/fee/detail/list`, params);
	},
	// 查询加保贴现申请审核里的票据包信息
	insuredDiscountApplyInfoApi(params) {
		return post(`/scb/v1/insured/discount/info/apply/bill/info`, params);
	},
}
export default scfBusinessApi
