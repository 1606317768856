import { post , get , put} from '../http';
import { getStorage } from '../../utils/tools'

export async function queryJdhOrgBankListApi(params){
    return post('/base/v1/jdh/org/bank/list', params,true,50000,"ap2");
} 

export async function queryJdhOrgpaymentApplyQueryApi(params){
    return post('/base/v1/jdh/org/payment/apply/query', params,true,50000,"ap2");
} 

export async function queryJdhOrgpaymentApplyApi(params){
    return post('/base/v1/jdh/org/payment/apply', params,true,50000,"ap2");
} 

export async function queryJdhOrgcheckAmountApi(params){
    return post('/base/v1/jdh/org/check/amount', params,true,50000,"ap2");
} 

export async function querySmsSendApi(params){
    return post('/scb/v1/issue/sms/send', params,true,50000,"ap2");
} 

export async function queryAgreementTemplateQueryApi(params){
    return post('/base/v1/jdh/org/agreement/template/query', params,true,50000,"ap2");
} 

export async function queryOrgAgreementSignApi(params){
    return post('/base/v1/jdh/org/agreement/sign', params,true,50000,"ap2");
} 

export async function queryOrgOpenInfoApi(params){
    return post('/base/v1/jdh/org/open/info', params,true,50000,"ap2");
}

export async function queryOrgRegisterQueryApi(params){
    return post('/base/v1/jdh/org/register/query', params,true,50000,"ap2");
} 

export async function queryOrgRegisterApi(params){
    return post('/base/v1/jdh/org/register', params,true,50000,"ap2");
} 

export async function queryOrgBankAddApi(params){
    return post('/base/v1/jdh/org/bank/add', params,true,50000,"ap2");
} 

export async function queryOrgBankReAddApi(params){
    return post('/base/v1/jdh/org/bank/reAdd', params,true,50000,"ap2");
} 

export async function queryOrgBankPageListApi(params){
    return post('/base/v1/jdh/org/bank/page/list', params,true,50000,"ap2");
} 

export async function queryorgBankPaymentApplyApi(params){
    return post('/base/v1/jdh/orgbank/payment/apply', params,true,50000,"ap2");
} 

export async function queryorgBankCheckAmountApi(params){
    return post('/base/v1/jdh/orgbank/check/amount', params,true,50000,"ap2");
} 

export async function queryorgBankQueryApi(params){
    return post('/base/v1/jdh/org/bank/query', params,true,50000,"ap2");
} 

export async function queryorgBankFileApi(params){
    return post('/base/v1/jdh/org/bank/file', params,true,50000,"ap2");
} 

export async function queryorgBankRegisterApi(params){
    return post('/base/v1/jdh/org/bank/register', params,true,50000,"ap2");
} 

//出票申请审核
export async function queryScbIssueApplyAuditApi(params){
    return post('/scb/v1/issue/apply/audit', params,true,50000,"ap2");
} 
//出票贸易背景审核
export async function queryScbIssueTradeBackgroundAuditApi(params){
    return post('/scb/v1/issue/trade/background/audit', params,true,50000,"ap2");
} 
//提示承兑申请审核
export async function queryScbIssuePromptAcceptApplyAuditApi(params){
    return post('/scb/v1/issue/prompt/accept/apply/audit', params,true,50000,"ap2");
} 
//承兑应答申请审核
export async function queryScbIssueAcceptResponseApplyAuditApi(params){
    return post('/scb/v1/issue/accept/response/apply/audit', params,true,50000,"ap2");
} 
//提示收票申请审核
export async function queryScbIssueAcceptReceiptApplyAuditApi(params){
    return post('/scb/v1/issue/prompt/receipt/apply/audit', params,true,50000,"ap2");
} 
//收票应答申请审核
export async function queryScbIssueAcceptReceiptResponseApplyAuditApi(params){
    return post('/scb/v1/issue/receipt/response/apply/audit', params,true,50000,"ap2");
} 
//贴现申请审核接口
export async function queryScbdiscountApplyAuditApi(params){
    return post('/scb/v1/discount/apply/audit', params,true,50000,"ap2");
} 
export async function queryScbdiscountApplyAuditParamApi(params){
    return post('/scb/v1/discount/audit/param', params,true,50000,"ap2");
} 
export async function queryScbplatformListApi(params){
    return post('/scb/v1/platform/list', params,true,50000,"ap2");
} 
export async function queryScbInstitutionsListApi(params){
    return post('/scb/v1/institutions/list', params,true,50000,"ap2");
} 
// 质押解除申请列表
export async function queryPledgeRelieveListApi(params){
    return post('/scb/v1/pledge/relieve/apply/list', params,true,50000,"ap2");
} 
// 质押解除信息详情
export async function queryPledgeRelieveDetailApi(params){
    return post('/scb/v1/pledge/relieve/getDetail', params,true,50000,"ap2");
}
// 质押解除申请
export async function applyPledgeRelieveApi(params){
    return post('/scb/v1/pledge/relieve/apply', params,true,50000,"ap2");
} 
// 质押解除申请审核
export async function auditPledgeRelieveApplyApi(params){
    return post('/scb/v1/pledge/relieve/apply/audit', params,true,50000,"ap2");
}
// 质押解除申请撤销
export async function applyPledgeRelieveRevokeApi(params){
    return post('/scb/v1/pledge/relieve/revoke/apply', params,true,50000,"ap2");
}
// 质押解除撤销申请审核
export async function auditPledgeRelieveRevokeApplyApi(params){
    return post('/scb/v1/pledge/relieve/revoke/apply/audit', params,true,50000,"ap2");
}
// 质押解除应答列表
export async function queryPledgeRelieveRespListApi(params){
    return post('/scb/v1/pledge/relieve/reply/list', params,true,50000,"ap2");
}
// 质押解除应答申请
export async function applyPledgeRelieveRespApi(params){
    return post('/scb/v1/pledge/relieve/reply/apply', params,true,50000,"ap2");
}
// 质押解除应答申请审核
export async function auditPledgeRelieveRespApplyApi(params){
    return post('/scb/v1/pledge/relieve/reply/apply/audit', params,true,50000,"ap2");
}
// 质押解除数据查询列表
export async function queryPledgeRelieveAllListApi(params){
    return post('/scb/v1/pledge/relieve/list', params,true,50000,"ap2");
}
// 质押数据查询列表
export async function queryPledgeAllListApi(params){
    return post('/scb/v1/pledge/list', params,true,50000,"ap2");
}
// 质押申请审核
export async function auditPledgeApplyApi(params){
    return post('/scb/v1/pledge/apply/audit', params,true,50000,"ap2");
}
// 质押信息详情
export async function queryPledgeDetailApi(params){
    return post('/scb/v1/pledge/getDetail', params,true,50000,"ap2");
}
// 质押撤销申请审核
export async function auditPledgeUndoApplyApi(params){
    return post('/scb/v1/pledge/revoke/apply/audit', params,true,50000,"ap2");
}
// 质押应答申请审核
export async function auditPledgeRespApplyApi(params){
    return post('/scb/v1/pledge/reply/apply/audit', params,true,50000,"ap2");
}
export async function getQuotaStatusApi(params){
    return post(`/business/dictionary/findDicLt`, params,true,50000,"ap2");
} 
// 质押申请列表
export async function pledgeApplyListApi(params){
    return post('/scb/v1/pledge/apply/list', params,true,50000,"ap2");
}
// 机构信息列表
export async function pledgeInstitutionsListApi(params){
    return post('/scb/v1/pledge/institutions/list', params,true,50000,"ap2");
}
// 质押申请
export async function pledgeapplyApi(params){
    return post('/scb/v1/pledge/apply', params,true,50000,"ap2");
}
// 质押信息详情
export async function pledgeGetDetailApi(params){
    return post('/scb/v1/pledge/getDetail', params,true,50000,"ap2");
}
// 质押申请审核
export async function pledgeapplyauditApi(params){
    return post('/scb/v1/pledge/apply/audit', params,true,50000,"ap2");
}
// 质押应答列表
export async function pledgereplyListApi(params){
    return post('/scb/v1/pledge/reply/list', params,true,50000,"ap2");
}
// 质押应答申请
export async function pledgeReplyApplyApi(params){
    return post('/scb/v1/pledge/reply/apply', params,true,50000,"ap2");
}
// 质押应答申请审核
export async function pledgeReplyApplyAuditApi(params){
    return post('/scb/v1/pledge/reply/apply/audit', params,true,50000,"ap2");
}
// 质押撤销申请
export async function pledRevokeApplyApi(params){
    return post('/scb/v1/pledge/revoke/apply', params,true,50000,"ap2");
}
// 质押撤销申请审核
export async function pledRevokeApplyAuditApi(params){
    return post('/scb/v1/pledge/revoke/apply/audit', params,true,50000,"ap2");
}
// 出票保证申请列表
export async function queryDrawEnsureApplyListApi(params){
    return post('/scb/v1/issue/ensure/apply/list', params,true,50000,"ap2");
}
// 机构信息列表
export async function queryInstitutionsListApi(params){
    return post('/scb/v1/institutions/query/list', params,true,50000,"ap2");
}
// 保证信息详情
export async function getEnsureInfoDetailApi(params){
    return post('/scb/v1/ensure/info/getDetail', params,true,50000,"ap2");
}
// 出票保证申请/重新申请
export async function applyDrawEnsureApi(params){
    return post('/scb/v1/issue/ensure/apply', params,true,50000,"ap2");
}
// 出票保证申请审核
export async function auditDrawEnsureApplyApi(params){
    return post('/scb/v1/issue/ensure/apply/audit', params,true,50000,"ap2");
}
// 出票保证申请撤销
export async function revokeDrawEnsureApplyApi(params){
    return post('/scb/v1/issue/ensure/apply/revoke', params,true,50000,"ap2");
}
// 出票保证申请撤销审核
export async function auditDrawEnsureApplyRevokeApi(params){
    return post('/scb/v1/issue/ensure/apply/revoke/audit', params,true,50000,"ap2");
}
// 出票保证应答列表
export async function queryDrawEnsureReplyListApi(params){
    return post('/scb/v1/issue/ensure/reply/list', params,true,50000,"ap2");
}
// 出票保证应答申请
export async function applyDrawEnsureReplyApi(params){
    return post('/scb/v1/issue/ensure/reply/apply', params,true,50000,"ap2");
}
// 出票保证应答申请审核
export async function auditDrawEnsureReplyApplyApi(params){
    return post('/scb/v1/issue/ensure/reply/apply/audit', params,true,50000,"ap2");
}
// 出票保证数据查询列表
export async function queryDrawEnsureAllListApi(params){
    return post('/scb/v1/issue/ensure/list', params,true,50000,"ap2");
}
// 承兑保证申请列表
export async function queryAcceptEnsureApplyListApi(params){
    return post('/scb/v1/accept/ensure/apply/list', params,true,50000,"ap2");
}
// 承兑保证申请/重新申请
export async function applyAcceptEnsureApi(params){
    return post('/scb/v1/accept/ensure/apply', params,true,50000,"ap2");
}
// 承兑保证申请审核
export async function auditAcceptEnsureApplyApi(params){
    return post('/scb/v1/accept/ensure/apply/audit', params,true,50000,"ap2");
}
// 承兑保证申请撤销
export async function revokeAcceptEnsureApplyApi(params){
    return post('/scb/v1/accept/ensure/apply/revoke', params,true,50000,"ap2");
}
// 承兑保证申请撤销审核
export async function auditAcceptEnsureApplyRevokeApi(params){
    return post('/scb/v1/accept/ensure/apply/revoke/audit', params,true,50000,"ap2");
}
// 承兑保证应答列表
export async function queryAcceptEnsureReplyListApi(params){
    return post('/scb/v1/accept/ensure/reply/list', params,true,50000,"ap2");
}
// 承兑保证应答申请
export async function applyAcceptEnsureReplyApi(params){
    return post('/scb/v1/accept/ensure/reply/apply', params,true,50000,"ap2");
}
// 承兑保证应答申请审核
export async function auditAcceptEnsureReplyApplyApi(params){
    return post('/scb/v1/accept/ensure/reply/apply/audit', params,true,50000,"ap2");
}
// 承兑保证数据查询列表
export async function queryAcceptEnsureAllListApi(params){
    return post('/scb/v1/accept/ensure/list', params,true,50000,"ap2");
}
// 背书保证申请列表
export async function queryEndorseEnsureApplyListApi(params){
    return post('/scb/v1/endorse/ensure/apply/list', params,true,50000,"ap2");
}
// 背书保证申请/重新申请
export async function applyEndorseEnsureApi(params){
    return post('/scb/v1/endorse/ensure/apply', params,true,50000,"ap2");
}
// 背书保证申请审核
export async function auditEndorseEnsureApplyApi(params){
    return post('/scb/v1/endorse/ensure/apply/audit', params,true,50000,"ap2");
}
// 背书保证申请撤销
export async function revokeEndorseEnsureApplyApi(params){
    return post('/scb/v1/endorse/ensure/apply/revoke', params,true,50000,"ap2");
}
// 背书保证申请撤销审核
export async function auditEndorseEnsureApplyRevokeApi(params){
    return post('/scb/v1/endorse/ensure/apply/revoke/audit', params,true,50000,"ap2");
}
// 背书保证应答列表
export async function queryEndorseEnsureReplyListApi(params){
    return post('/scb/v1/endorse/ensure/reply/list', params,true,50000,"ap2");
}
// 背书保证应答申请
export async function applyEndorseEnsureReplyApi(params){
    return post('/scb/v1/endorse/ensure/reply/apply', params,true,50000,"ap2");
}
// 背书保证应答申请审核
export async function auditEndorseEnsureReplyApplyApi(params){
    return post('/scb/v1/endorse/ensure/reply/apply/audit', params,true,50000,"ap2");
}
// 背书保证数据查询列表
export async function queryEndorseEnsureAllListApi(params){
    return post('/scb/v1/endorse/ensure/list', params,true,50000,"ap2");
}
// 运营-出票数据查询列表
export async function queryDrawListApi(params){
    return post('/scb/v1/issue/data/list', params,true,50000,"ap2");
}
// 运营-出票保证数据查询列表
export async function queryDrawGuaranteeListApi(params){
    return post('/scb/v1/issue/ensure/data/list', params,true,50000,"ap2");
}
// 运营-承兑保证数据列表
export async function queryAcceptGuaranteeListApi(params){
    return post('/scb/v1/accept/ensure/data/list', params,true,50000,"ap2");
}
// 运营-背书数据查询列表
export async function queryEndorseListApi(params){
    return post('/scb/v1/endorsement/data/list', params,true,50000,"ap2");
}
// 运营-背书保证数据查询列表
export async function queryEndorseGuaranteeListApi(params){
    return post('/scb/v1/endorse/ensure/data/list', params,true,50000,"ap2");
}
// 运营-质押解除数据查询列表
export async function queryDischargeListApi(params){
    return post('/scb/v1/pledge/relieve/data/list', params,true,50000,"ap2");
}
// 运营-质押数据查询列表
export async function queryPledgeListApi(params){
    return post('/scb/v1/pledge/data/list', params,true,50000,"ap2");
}
// 运营-兑付数据查询列表
export async function queryAcceptListApi(params){
    return post('/scb/v1/accept/data/list', params,true,50000,"ap2");
}
// 运营-追索数据查询列表
export async function queryPressListApi(params){
    return post('/scb/v1/recourse/data/list', params,true,50000,"ap2");
}
// 运营-同意清偿数据查询列表
export async function queryLiquidateListApi(params){
    return post('/scb/v1/recourse/liquidate/data/list', params,true,50000,"ap2");
}
// 运营-贴现数据查询列表
export async function queryDiscountListApi(params){
    return post('/scb/v1/discount/data/list', params,true,50000,"ap2");
}
//保证管理

// 产品分页列表
export async function queryProductsListApi(params){
    return post('/scb/v1/products/page', params,true,50000,"ap2");
}
// 产品添加
export async function queryProductsAddApi(params){
    return post('/scb/v1/products', params,true,50000,"ap2");
}
// 产品详情
export async function queryProductsDetailApi(productid){
    return get(`/scb/v1/products/${productid}`,true,50000,"ap2");
}
// 产品修改
export async function queryProductsPutApi(productid){
    return put(`/scb/v1/products/${productid}`,true,50000,"ap2");
}
// 产品修改状态
export async function queryProductsStatusPutApi(productid){
    return put(`/scb/v1/products/${productid}/status`,true,50000,"ap2");
}
// 产品承兑分页列表
export async function queryProductsAcceptorsApi(params){
    return post(`/scb/v1/products/acceptors/page`,params,true,50000,"ap2");
}
// 产品承兑添加
export async function queryProductsAcceptorsAddApi(params){
    return post(`/scb/v1/products/acceptors`,params,true,50000,"ap2");
}
// 产品承兑详情
export async function queryProductsAcceptorsDetailApi(acceptorId){
    return get(`/scb/v1/products/acceptors/${acceptorId}`,true,50000,"ap2");
}
// 产品承兑修改
export async function queryProductsAcceptorsPutApi(params){
    return put(`/scb/v1/products/acceptors/${params.id}`,params,true,50000,"ap2");
}
// 产品承兑修改状态
export async function queryProductsAcceptorsStatusPutApi(acceptorId){
    return put(`/scb/v1/products/acceptors/${acceptorId}/status`,true,50000,"ap2");
}
// 产品承兑导出全部
export async function queryProductsAcceptorsExportAllApi(params){
    return post(`/scb/v1/products/acceptors/export/all`,params,true,50000,"ap2");
}
// 产品承兑导入解析
export async function queryProductsAcceptorsExportAnalysisApi(params){
    return post(`/scb/v1/products/acceptors/import/analysis`,params,true,50000,"ap2");
}
// 产品承兑导入确认
export async function queryProductsAcceptorsExportConfirmApi(params){
    return post(`/scb/v1/products/acceptors/import/confirm`,params,true,50000,"ap2");
}
// 产品承兑-产品列表
export async function queryProductNameListApi(params){
    return get(`/scb/v1/products`,params,true,50000,"ap2");
}
// 文件上传
export async function queryfileUploadApi(params){
    return post(`/business/resourcefile/upload`,params,true,50000,"ap2");
}
// 产品分页列表
export async function queryProductsPageListApi(params){
    return post(`/scb/v1/products/page`,params,true,50000,"ap2");
}
// 产品添加
export async function queryProductsPageAddApi(params){
    return post(`/scb/v1/products`,params,true,50000,"ap2");
}
// 产品详情
export async function queryProductsPageDetailApi(productid){
    return get(`/scb/v1/products/${productid}`,true,50000,"ap2");
}
// 产品修改
export async function queryProductsPagePutApi(params){
    return put(`/scb/v1/products/${params.id}`,params,true,50000,"ap2");
}
// 产品修改状态
export async function queryProductsPagePutStatusApi(productid){
    return put(`/scb/v1/products/${productid}/status`,true,50000,"ap2");
}

// 保证人加保管理-分页列表
export async function queryInsuredDiscountReplyListApi(params){
    return post(`/scb/v1/insured/discount/reply/page`,params,true,50000,"ap2");
}
// 保证人加保管理-审核
export async function queryInsuredDiscountReplyAuditApi(params){
    return post(`/scb/v1/insured/discount/reply/${params.insuredDiscountReplyId}/audit`,params,true,50000,"ap2");
}





// 自主支付费用-线下支付
export async function payDiscountOfflineApi(params){
    return post('/scb/v1/fee/offline/confirmation/payment', params,true,50000,"ap2");
}
// 拆票支付费用-支付
export async function payDiscountSplitApi(params){
    return post('/scb/v1/fee/apply/split/ticket/confirmation/payment', params,true,50000,"ap2");
}
// 加保贴现-发起可用票据分页列表
export async function queryUsableTicketListApi(params){
    return post('/scb/v1/insured/discount/usable/ticket/list', params,true,50000,"ap2");
}
// 加保贴现-加保产品列表
export async function queryInsuredProductListApi(params){
    return post('/scb/v1/insured/product/list', params,true,50000,"ap2");
}
// 加保贴现-加保产品列表试算
export async function trialsInsuredProductListApi(params){
    return post('/scb/v1/insured/discount/trials', params,true,50000,"ap2");
}
// 加保贴现-单个产品试算
export async function trialInsuredProductApi(params){
    return post('/scb/v1/insured/discount/trial', params,true,50000,"ap2");
}
// 加保贴现-保证金额试算
export async function trialInsuredAmountApi(params){
    return post('/scb/v1/insured/discount/ensure/amount/trial', params,true,50000,"ap2");
}
// 加保贴现-初始申请
export async function applyInsuredInitApi(params){
    return post('/scb/v1/insured/discount/init/apply', params,true,50000,"ap2");
}
// 加保贴现-初始重新申请
export async function reApplyInsuredInitApi(params){
    return post('/scb/v1/insured/discount/init/reapply', params,true,50000,"ap2");
}
// 加保贴现-申请列表
export async function queryInsuredApplyListApi(params){
    return post('/scb/v1/insured/discount/apply/list', params,true,50000,"ap2");
}
// 加保贴现信息详情接口
export async function getInsuredDiscountDetailsApi(params){
    return post('/scb/v1/insured/discount/apply/details', params,true,50000,"ap2");
}
// 加保贴现-初始申请审核
export async function auditInsuredInitApplyApi(params){
    return post('/scb/v1/insured/discount/init/apply/audit', params,true,50000,"ap2");
}
// 费用详情
export async function getFeeDetailApi(params){
    return post('/scb/v1/fee/detail', params,true,50000,"ap2");
}
// 加保贴现-服务费-线上支付
export async function payServiceOnlineApi(params){
    return post('/scb/v1/insured/discount/service/online/pay', params,true,50000,"ap2");
}
// 加保贴现-服务费-拆票支付
export async function payServiceSplitApi(params){
    return post('/scb/v1/insured/discount/service/split/ticket/pay', params,true,50000,"ap2");
}
// 加保贴现-保费-线上支付
export async function payPremiumOnlineApi(params){
    return post('/scb/v1/insured/discount/premium/online/pay', params,true,50000,"ap2");
}
// 加保贴现-保费-拆票支付
export async function payPremiumSplitApi(params){
    return post('/scb/v1/insured/discount/premium/split/ticket/pay', params,true,50000,"ap2");
}
// 加保贴现申请查询
export async function queryInsuredFeeStatusApi(params){
    return post('/scb/v1/insured/discount/apply/query', params,true,50000,"ap2");
}
// 保费详情-自主支付
export async function getInsuredPremiumDetailApi(params){
    return post('/scb/v1/insured/discount/info/auto/detail', params,true,50000,"ap2");
}
// 加保贴现-服务费线下确认支付
export async function payInsuredServiceOfflineApi(params){
    return post('/scb/v1/insured/discount/service/offline/confirm/payment', params,true,50000,"ap2");
}
// 加保贴现-保费线下确认支付
export async function payInsuredPremiumOfflineApi(params){
    return post('/scb/v1/insured/discount/premium/offline/confirm/payment', params,true,50000,"ap2");
}
// 加保贴现-背书保证申请
export async function applyInsuredEndorseApi(params){
    return post('/scb/v1/insured/discount/endorse/ensure/apply', params,true,50000,"ap2");
}
// 产品下承兑人列表
export async function getProductAcceptorListApi(productId,name=''){
    return get(`/scb/v1/products/${productId}/acceptors?name=${name}`, null,true,50000,"ap2");
}
// 贴现-自主支付-线下支付
export async function payOfflineConfirmeApi(params){
    return post('/scb/v1/fee/offline/confirmation/payment', params,true,50000,"ap2");
}
// 自主支付费用-线上支付
export async function payOnlineConfirmeApi(params){
    return post('/scb/v1/fee/online/confirmation/payment', params,true,50000,"ap2");
}
// 加保贴现-服务费/保费-拆票支付-查询状态
export async function querySplitStatusApi(params){
    return post('/scb/v1/insured/discount/endorse/apply/query', params,true,50000,"ap2");
}
// 加保贴现-邮箱列表
export async function queryEmailListApi(params){
    return post('/scb/v1/email/list', params,true,50000,"ap2");
}
//关联资源文件查询
export async function fileRelationQueryApi(params){
    return post('/scb/v1/file/relation/query', params,true,50000,"ap2");
}
//保证人加保管理-担保人审核
export async function fileReplyGuarantorsAuditApi(params,insuredDiscountApplyId){
    return post(`/scb/v1/insured/discount/reply/${insuredDiscountApplyId}/guarantors/audit`, params,true,50000,"ap2");
}
//保证人加保管理-应答
export async function fileReplyGuarantorsendorseauditauditApi(params,insuredDiscountApplyId){
    return post(`/scb/v1/insured/discount/reply/${insuredDiscountApplyId}/reply/audit`, params,true,50000,"ap2");
}
//保证人加保管理-应答审核
export async function fileReplyGuarantorsendorseAuditApi(params,insuredDiscountReplyId){
    return post(`/scb/v1/insured/discount/reply/${insuredDiscountReplyId}/reply`, params,true,50000,"ap2");
}
//合同生成
export async function generateContractsApi(params,scbTicketPackageId){
    return post(`/scb/v1/${scbTicketPackageId}/contracts/generate?scbFeeTypeCd=${params.scbFeeTypeCd}&scbBusinessTypeCd=${params.scbBusinessTypeCd}&relationId=${params.relationId}`, {},true,50000,"ap2");
}
//合同签署
export async function signContractsApi(params,scbTicketPackageId){
    return post(`/scb/v1/${scbTicketPackageId}/contracts/sign?scbFeeTypeCd=${params.scbFeeTypeCd}&scbBusinessTypeCd=${params.scbBusinessTypeCd}`, {},true,50000,"ap2");
}
//合同是否存在
export async function checkContractsGeneratedApi(params,scbTicketPackageId){
    return get(`/scb/v1/${scbTicketPackageId}/contracts?scbFeeTypeCd=${params.scbFeeTypeCd}&scbBusinessTypeCd=${params.scbBusinessTypeCd}`, {},true,50000,"ap2");
}