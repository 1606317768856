import React, { Component } from 'react';
import { Form, Row, Col, Input, DatePicker, Select, Button, message, Spin } from 'antd';
import scfBusinessApi from '../../../request/api/scfBusinessApi';
import { queryDrawListApi } from '../../../request/api/jdhRequest';
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import '../index.scss'
import HistoryListTable from '../jdhDrawer/component/historyListTable';
const { RangePicker } = DatePicker;
const { Option } = Select;
@withRouter
class DrawHistoryList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {},
            ticketStatusList: [],             //票据状态
            flowStatusList: [],               //票据流转状态
            pageNum: 1,
            pageSize: 10,
        }
    }
    componentDidMount() {
        this.getList({ current: this.state.pageNum, pageSize: this.state.pageSize, })
        this.getDicList('scbTicketStatusCd', 'ticketStatusList')      //获取票据状态列表
        this.getDicList('ticketFlowStatusCd', 'flowStatusList')      //获取票据状态列表
    }
    getList = (pagination) => {
        this.setState({ loading: true })
        let values = this.props.form.getFieldsValue();
        if (values.ticketDate && values.ticketDate.length > 0) {
            values.ticketStartDate = values.ticketDate ? moment(values.ticketDate[0]).format('YYYY-MM-DD') : ''
            values.ticketEndDate = values.ticketDate ? moment(values.ticketEndDate[1]).format('YYYY-MM-DD') : ''
        }
        if (values.expiredDate && values.expiredDate.length > 0) {
            values.ticketExpireStartDate = values.expiredDate ? moment(values.expiredDate[0]).format('YYYY-MM-DD') : ''
            values.ticketExpireEndDate = values.expiredDate ? moment(values.expiredDate[1]).format('YYYY-MM-DD') : ''
        }
        delete values.ticketDate
        delete values.expiredDate
        values.pageNum = pagination.current
        values.pageSize = pagination.pageSize
        queryDrawListApi(values).then(res => {
            this.setState({
                data: res.data,
                pageNum: res.data.pageNum,
                pageSize: res.data.pageSize,
            })
        }).catch(err => {
            message.error(err.message)
        }).finally(() => {
            this.setState({ loading: false })
        })
    }
    getDicList = (dicType, param) => {
        scfBusinessApi.findDicLtApi({ dicType }).then(res => {
            this.setState({ [param]: res.data })
        }).catch(err => {
            message.error(err.message)
        })
    }
    handleReset = () => {
        this.props.form.resetFields();
        this.getList({ current: this.state.pageNum, pageSize: this.state.pageSize, })
    }

    handleSearch = () => {
        this.getList({ current: this.state.pageNum, pageSize: this.state.pageSize, })
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.getList({ current: pagination.current, pageSize: pagination.pageSize, })
    };
    render() {
        const { loading, ticketStatusList, flowStatusList, data } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
            labelAlign: 'right'
        }
        return (
            <div>
                <Spin spinning={loading}>
                    <Form {...formItemLayout} className='search-box'>
                        <Row>
                            <Col span={8}>
                                <Form.Item label='票据包号'>
                                    {
                                        getFieldDecorator('packageNo', {
                                            initialValue: '',
                                        })(<Input />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='出票日期'>
                                    {
                                        getFieldDecorator('ticketDate', {
                                            initialValue: '',
                                        })(<RangePicker />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='票据到期日期'>
                                    {
                                        getFieldDecorator('expiredDate', {
                                            initialValue: '',
                                        })(<RangePicker />)
                                    }
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label='出票人'>
                                    {
                                        getFieldDecorator('drawerName', {
                                            initialValue: '',
                                        })(<Input />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='收票人'>
                                    {
                                        getFieldDecorator('payeeName', {
                                            initialValue: '',
                                        })(<Input />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='承兑人'>
                                    {
                                        getFieldDecorator('acceptorName', {
                                            initialValue: '',
                                        })(<Input />)
                                    }
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label="票据状态">
                                    {
                                        getFieldDecorator('scbTicketStatusCd', {
                                            initialValue: '',
                                        })(
                                            <Select
                                                showSearch
                                                placeholder="请选择票据状态"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value=''>全部</Option>
                                                {
                                                    ticketStatusList && ticketStatusList.length > 0 ? ticketStatusList.map((record) => {
                                                        return <Option value={record.dicKey} key={record.id}>{record.dicValue}</Option>
                                                    }) : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="流转状态">
                                    {
                                        getFieldDecorator('ticketFlowStatusCd', {
                                            initialValue: '',
                                        })(
                                            <Select
                                                showSearch
                                                placeholder="请选择流转状态"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value=''>全部</Option>
                                                {
                                                    flowStatusList && flowStatusList.length > 0 ? flowStatusList.map((record) => {
                                                        return <Option value={record.dicKey} key={record.id}>{record.dicValue}</Option>
                                                    }) : null
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className='wraper-cd-center box_md'>
                            <Button type='default' className='com-btn-no com-middle-btn-size mr20' onClick={this.handleReset}>重置</Button>
                            <Button type='primary' className='com-btn-no com-middle-btn-size mr20' onClick={this.handleSearch}>查询</Button>
                        </div>
                    </Form>
                    <HistoryListTable data={data} handleTableChange={this.handleTableChange} />
                </Spin>
            </div>
        );
    }
}
export default Form.create()(DrawHistoryList);