//验证手机号
export const checkPhone = (rule, value, callback) => {
  if (value != '' && value != undefined) {
    if (!/^1([3-9][0-9])\d{8}$/.test(value)) {
      callback('手机号码有误，请重填')
    } else {
      callback()
    }
  } else {
    callback()
  }
}

//手机号中间显示****
export const noPassByMobile = (value) => {
  if (null != value && value != undefined) {
    var pat = /(\d{3})\d*(\d{4})/
    return value.replace(pat, '$1****$2')
  } else {
    return ''
  }
}

//验证码为6位
export const checkCode = (rule, value, callback) => {
  if (value.length < 6) {
    callback('验证码格式有误，请重新填写')
  }
}

//验证是否为空
export const isEmpyt = (params) => {
  if (params == '' || params == undefined || params == null|| params == 'undefined') {
    return true
  } else {
    return false
  }
}

//验证固定电话(即座机)格式     0开头的3-4位区号，7-8位电话号，1-6位分机号
export const checkFixedPhone = (rule, value, callback) => {
  if (value != '' && value != undefined) {
    if (!/^(0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,6})?$/.test(value)) {
      callback('电话号码格式有误，请重填')
    } else {
      callback()
    }
  } else {
    callback()
  }
}

//银行卡卡号校验
export const checkBankNumber = (rule, value, callback) => {
  if (value != '' && value != undefined) {
    if (!/^\d{16,21}$/.test(value)) {
      callback('银行卡号格式有误，请重填')
    } else {
      callback()
    }
  } else {
    callback()
  }
}

//验证联系电话(手机号和固定电话)
export const checkFixedOrMobilePhone = (rule, value, callback) => {
  if (value != '' && value != undefined) {
    if (
      !/^(0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,6})?$/.test(value) &&
      !/^1(3|4|5|7|8)\d{9}$/.test(value)
    ) {
      callback('固定电话或移动电话格式有误，请重填')
    } else {
      callback()
    }
  } else {
    callback()
  }
}

//登录密码设置验证
export const checkPwd = (rule, value, callback) => {
  if (!/^(?=.*[0-9])(?=.*[a-zA-Z])(.{6,20})$/.test(value)) {
    callback('6-20位，英文数字，不能为纯数字纯英文')
  } else {
    callback()
  }
}

//邮箱验证 && 手机号验证
export const checkEmailPhone = (rule, value, callback) => {
  const re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
  if (re.test(value) || /^1(3|4|5|7|8)\d{9}$/.test(value)) {
    callback()
  } else {
    callback('邮箱号有误，请重填')
  }
}

//金额格式化
export const money100 = (num) => {
  return (num || 0).toLocaleString('zh', { minimumFractionDigits: 2 })
}

//金额格式化，最大为两位小数，且去掉小数点后多余的0
export const moneyClearZero100 = (num) => {
  return (num || 0).toLocaleString('zh', { maximumFractionDigits: 2 })
}

//金额格式化，未设置小数位数
export const moneyFormat = (num) => {
  return (num || 0).toLocaleString('zh', { style: 'decimal' })
}

//金额格式化多位
export const moneyMore = (num, dight) => {
  return (num || 0).toLocaleString('zh', { minimumFractionDigits: dight })
}

//加百分号
export const toPercent = (point) => {
  return point + '%'
}
//每三位加逗号,保留小数位
export const money = (num) => {
  var a = num.toString()
  if (a.indexOf('.') == -1) {
    return Number(a).toLocaleString() + '.00'
  }
  var str = a.split('.')[0]
  var c = Number(str)
  var b = c.toLocaleString()
  return (b += '.' + a.split('.')[1])
}

//验证身份证号格式
export const checkIdNumber = (rule, value, callback) => {
  if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
    callback('身份证号格式有误，请重填')
  } else {
    callback()
  }
}

//验证邮政编码
export const checkZipCode = (rule, value, callback) => {
  if (value != '' && value != undefined) {
    if (!/^[1-9][0-9]{5}$/.test(value)) {
      callback('邮编格式有误，请重填')
    } else {
      callback()
    }
  } else {
    callback()
  }
}

//统一社会信用代码校验
export const checkUnifiedCreditCode = (rule, value, callback) => {
  if (value != '' && value != undefined) {
    if (
      !/^(11|12|13|19|51|52|53|59|91|92|93|Y1)\d{6}[0-9A-HJ-NP-RTUW-Y]{10}$/.test(
        value
      )
    ) {
      callback('统一社会信用代码格式有误，请重填')
    } else {
      callback()
    }
  } else {
    callback()
  }
}

//组织机构代码号校验
export const checkOrganizationCode = (rule, value, callback) => {
  if (
    value != undefined &&
    value != '' &&
    !/^[A-Z0-9]{8}-[A-Z0-9]$/.test(value)
  ) {
    callback('组织机构代码号格式有误，请重填')
  } else {
    callback()
  }
}

//校验input框输入数值为整数
export const checkDataInt = (rule, value, callback, max) => {
  let reg = /^(?!\d*\.\d*$)([1-9]\d*|0)$/
  let isInt = reg.test(value)
  if (isInt == false) {
    callback(`该数值为整数`)
  } else if (isInt == true && max != undefined && value > max) {
    callback('该数值超限')
  } else {
    callback()
  }
}

//校验input输入数值最多保留n位小数,最大值为max,最小值为min
export const checkDataPrecision = (rule, value, callback, n = 0, max, min) => {
  // /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,4})?$/
  let reg = new RegExp(
    '^(?!0+(?:\\.0+)?$)(?:[1-9]\\d*|0)(?:\\.\\d{1,' + n + '})?$'
  )
  let isCorrectFaceValueFormat = reg.test(value)
  if (isCorrectFaceValueFormat == false) {
    callback(`该数值最多保留${n}位小数且大于0`)
  } else if (isCorrectFaceValueFormat == true && min != null && value < min) {
    callback(`该数值不低于${min}`)
  } else if (isCorrectFaceValueFormat == true && max != null && value > max) {
    callback('该数值超限')
  } else {
    callback()
  }
}

/**
 * 转为大写金额
 * @param n
 * @returns {string}
 * @constructor
 */
 export const toCapitalizedChinese = (n) => {
	if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
		return "数据非法";  //判断数据是否大于0
	}
	var unit = "千百拾亿千百拾万千百拾元角分", str = "";
	n += "00";
	var indexpoint = n.indexOf('.');
	// 如果是小数，截取小数点前面的位数
	if (indexpoint >= 0) {
		// 若为小数，截取需要使用的unit单位
		n = n.substring(0, indexpoint) + n.substr(indexpoint + 1, 2);
	}
	// 若为整数，截取需要使用的unit单位
	unit = unit.substr(unit.length - n.length);
	for (var i = 0; i < n.length; i++) {
		//遍历转化为大写的数字
		str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
	}
	// 替换掉数字里面的零字符，得到结果
	return str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
}

//校验input输入数值最多保留n位小数,最大值为max,最小值为min,可等于0
export const checkPrecision = (rule, value, callback, n = 0, max, min) => {
  let reg = new RegExp(
    '^(?!0+(?:\\.0+)?$)(?:[1-9]\\d*|0)(?:\\.\\d{1,' + n + '})?$'
  )
  let isCorrectFaceValueFormat = reg.test(value)
  if (isCorrectFaceValueFormat == false && value != 0) {
    callback(`该数值最多保留${n}位小数且大于等于0`)
  } else if (isCorrectFaceValueFormat == true && min != null && value <= min) {
    callback(`该数值不低于${min}`)
  } else if (isCorrectFaceValueFormat == true && max != null && value > max) {
    callback('该数值超限')
  } else {
    callback()
  }
}

//防重复提交
export const debounce = (event, wait) => {
  let timer = null;
  return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
          event(...args);
      }, wait);
  };
};

export const formatSubTicketInterval = (subTicketInterval) => {
  let rg = /[1-9][0-9]{0,11}/g
  let value = null
  if(subTicketInterval){
    const arr = subTicketInterval.match(rg)
    value = `${arr[0]}-${arr[1]}`
  }
  return value
}

export const deal = (des, fileList) => {
  let text = des
  let fileName = ''
  for(let i = 0; i< fileList.length;i++){
    if(des.includes(`《{{${i+1}}}》`)){
      fileName = fileList[i].fileName.substring(0,fileList[i].fileName.lastIndexOf('.'))
      fileName = `<a class="com-hand-blue" href="${fileList[i].fileUrl}">《${fileName}》</a>`
      text=text.replace(`《{{${i+1}}}》`,fileName)
    }
  }
  return text
}