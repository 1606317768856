import { Button, Table } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { money100 } from '../../../../utils/formatCheck';
@withRouter
class HistoryListTable extends Component {
  toDetail = (record) => {
    this.props.history.push(`/admin/draw/detail?id=${record.id}&wkId=${record.wkId}&flowStatus=${record.ticketFlowStatusCdDesc}`)
  }
  render() {
    const { data } = this.props;
    const columns = [
      {
        title: '#',
        width: 80,
        render: (text, record, index) => {
          return <span>{index + 1}</span>
        }
      },
      {
        title: '票据包号',
        dataIndex: 'packageNo',
      },
      {
        title: '子票区间',
        dataIndex: 'subTicketInterval',
      },
      {
        title: '票据包金额（元）',
        width: 160,
        dataIndex: 'packageAmount',
        render: (text) => {
          return text ? money100(text) : '--'
        }
      },
      {
        title: '出票日期',
        width: 120,
        dataIndex: 'ticketDate',
      },
      {
        title: '票据到期日期',
        width: 120,
        dataIndex: 'ticketExpireDate',
      },
      {
        title: '票据状态',
        width: 120,
        dataIndex: 'scbTicketStatusCdDesc',
      },
      {
        title: '流转状态',
        width: 180,
        dataIndex: 'ticketFlowStatusCdDesc',
      },
      {
        title: '出票人名称',
        dataIndex: 'drawerName',
      },
      {
        title: '出票账号',
        dataIndex: 'drawerAccountNo',
      },
      {
        title: '出票开户行',
        dataIndex: 'drawerBankName',
      },
      {
        title: '收票人名称',
        dataIndex: 'payeeName',
      },
      {
        title: '收票人账号',
        dataIndex: 'payeeAccountNo',
      },
      {
        title: '收票人开户行',
        dataIndex: 'payeeBankName',
      },
      {
        title: '承兑人名称',
        dataIndex: 'acceptorName',
      },
      {
        title: '承兑人账号',
        dataIndex: 'acceptorAccountNo',
      },
      {
        title: '承兑人开户行',
        dataIndex: 'acceptorBankName',
      },
      {
        title: '操作',
        fixed: 'right',
        width: 250,
        render: (record) => {
          return <Button type='default' className='com-btn-opr mr5' onClick={() => this.toDetail(record)}>票面</Button>
        }
      },
    ]
    return (
      <>
        <Table
          columns={columns}
          dataSource={data.list}
          rowKey={(record, index) => record.id}
          scroll={{ x: 4400 }}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: total => `总共 ${data.total} 条`,
            total: data.total,
            current: data.pageNum,
            pageSize: data.pageSize,
          }}
          onChange={this.props.handleTableChange}
        />
      </>
    );
  }
}
export default HistoryListTable;