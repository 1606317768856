import drawHistoryList from '../containers/jdhManagement/operationalHistoryList/drawHistoryList';
import loadable from '../utils/loadable'

const App = loadable(() => import('@/containers/App'));
const Home = loadable(() => import('@/containers/home'));
const Login = loadable(() => import('@/containers/login'));
const BusinessRoleSelect = loadable(() => import('../containers/businessRoleSelect'));
const BusinessRoleSelectJdh = loadable(() => import('../containers/businessRoleSelectJdh'));
const Register = loadable(() => import('../containers/login/register'));
const SetDealPassword = loadable(() => import('../containers/login/setDealPassword'));
const ForgotPassword = loadable(() => import('../containers/login/forgotPassword'));
const SmsCode = loadable(() => import('../containers/login/smsCode'));

const ErrorPage = loadable(() => import('../../src/components/errorPage/index'));

const DealWith = loadable(() => import('../containers/workbench/DealWith'));
const WaitDealt = loadable(() => import('@/containers/workbench/WaitDealt'));

const SupplyChainTicket = loadable(() => import('../containers/supplyChainTicket'));
const OperatorManagement = loadable(() => import('../containers/supplyChainTicket/component/operatorIndex'));

const AccountManage = loadable(() => import('../containers/accountManage/index'));
const Addqichannel = loadable(() => import('../containers/accountManage/component/addqichannel'));
const Moneyaudit = loadable(() => import('../containers/accountManage/component/moneyAudit'));
const TransactionDetails = loadable(() => import('../containers/accountManage/TransactionDetails'));

// 简单会
const Platforminfo = loadable(() => import('../containers/jdhManagement/jdhOrgInfo'));
const OrgInfo = loadable(() => import('../containers/jdhManagement/jdhOrgInfo/component/orgIndex'));
const DiscountforAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/discountforAudit'));
const InsuredDiscountAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/insuredDiscountAudit'));
const GetTicketReplyAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/getTicketReplyAudit'));
const GetTicketPromptAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/getTicketPromptAudit'));
const AcceptanceReplyAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/acceptanceReplyAudit'));
const PromptAcceptanceAudit  = loadable(() => import('../containers/jdhManagement/jdhAudit/promptAcceptanceAudit'));
const TradeBackgroundAudit = loadable(() => import('../containers/jdhManagement/jdhAudit/trardBgAudit'));
const DrawerBill = loadable(() => import('../containers/jdhManagement/jdhAudit/index'));
const OperatorManageModify = loadable(() => import('../containers/jdhManagement/telexAccountManage/components/operatorManageModify'));
const OperatorManageAdd = loadable(() => import('../containers/jdhManagement/telexAccountManage/components/operatorManageAdd'));
const TicketRegistration = loadable(() => import('../containers/jdhManagement/telexAccountManage/components/ticketRegistration'));
const CompTicketRegistration = loadable(() => import('../containers/jdhManagement/telexAccountManage/companyComponents/ticketRegistration'));
const TelexAccountManage = loadable(() => import('../containers/jdhManagement/telexAccountManage'));
const EnterpriseAccountAdd = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseAccountAdd'));
const EnterpriseRegistration = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseRegistration'));
const JdhCertification = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseCertification'));
const JsdDeal = loadable(() => import('../containers/jdhManagement/jdhRegister/components/enterpriseDeal'));
const JdhCompCertification = loadable(() => import('../containers/jdhManagement/jdhRegister/companyComponents/enterpriseCertification'));
const JsdCompDeal = loadable(() => import('../containers/jdhManagement/jdhRegister/companyComponents/enterpriseDeal'));
const JsdAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/index'));
const JsdReply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseReply'));
const JsdSearch = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseSearch'));
const JsdInfoEdit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseInfoEdit'));
const JsdApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseApplyAudit'));
const JsdRevokeaudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/jsdRevokeaudit'));
const JsdApplyCheck = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseApplyCheck'));
const JsdReplyApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseReplyApplyAudit'));
const JsdRevoke = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseRevoke'));
const JsdReplyApply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseReplyApply'));
const JsdParInfo = loadable(() => import('../containers/jdhManagement/endorseTwo/component/endorseParInfo'));
const EndorseGuaranteeList = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeList'));
const EndorseGuaranteeApply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeApply'));
const EndorseGuaranteeCheck = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeCheck'));
const EndorseGuaranteeApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeApplyAudit'));
const EndorseGuaranteeRevocation = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRevocation'));
const EndorseGuaranteeRevocationAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee//endorseGuaranteeRevocationAudit'));
const EndorseGuaranteeRespList = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRespList'));
const EndorseGuaranteeRespApply = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRespApply'));
const EndorseGuaranteeRespApplyAudit = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeRespApplyAudit'));
const EndorseGuaranteeHistoryList = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeHistory'));
const EndorseGuaranteeDetail = loadable(() => import('../containers/jdhManagement/endorseTwo/component/guarantee/endorseGuaranteeDetail'));

const SuccessPage = loadable(() => import('../containers/jdhManagement/successPage/index'));
const DrawerIndex = loadable(() => import('../containers/jdhManagement/jdhDrawer/index'));
const DiscountIndex = loadable(() => import('../containers/jdhManagement/jdhDiscount/index'));
const CashIndex = loadable(() => import('../containers/jdhManagement/jdhCash/index'));
const DiscountCost = loadable(() => import('../containers/jdhManagement/discountCost/index'));
const SystemManagement = loadable(() => import('../containers/jdhManagement/system/index'));

const PledgeIndex = loadable(() => import('../containers/jdhManagement/jdhPledge/index'))
const PledgeList = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/index'))
const PledgeInfoEdit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeInfoEdit'))
const PledgeApplyCheck = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyCheck'))
const PledgeApplyAudit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyAudit'))
const PledgeAnswer = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyAnswer'))
const PledgeAnswerApply = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeAnswerApply'))
const PledgeAnswerApplyAudit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeAnswerApplyAudit'))
const PledgeApplyRevoke = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyRevoke'))
const PledgeApplyRevokeAudit = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyRevokeAudit'))
const PledgeDetail = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeDetail'))
const PledgeApplyDetail = loadable(() => import('../containers/jdhManagement/jdhPledge/pledge/component/pledgeApplyDetail'))

const PressIndex = loadable(() => import('../containers/jdhManagement/jdhPress/index'));
const DrawHistoryList = loadable(() => import('../containers/jdhManagement/operationalHistoryList/drawHistoryList'));
const DrawGuaranteeHistoryList = loadable(() => import('../containers/jdhManagement/operationalHistoryList/drawGuaranteeHistoryList'));
const AcceptGuaranteeHistoryList = loadable(() => import('../containers/jdhManagement/operationalHistoryList/acceptGuaranteeHistoryList'));
const EndorseHistoryList = loadable(() => import('../containers/jdhManagement/operationalHistoryList/endorseSearch'));
const EndorseGuaranteeHistory = loadable(() => import('../containers/jdhManagement/operationalHistoryList/endorseGuaranteeHistory'));
const DiscountHistory = loadable(() => import('../containers/jdhManagement/operationalHistoryList/discountHistoryList'));
const PledgeHistory = loadable(() => import('../containers/jdhManagement/operationalHistoryList/pledgeHistory'));
const DischargeHistory = loadable(() => import('../containers/jdhManagement/operationalHistoryList/dischargeHistory'));
const AcceptHistory = loadable(() => import('../containers/jdhManagement/operationalHistoryList/acceptHistoryList'));
const PressHistory = loadable(() => import('../containers/jdhManagement/operationalHistoryList/pressHistoryList'));
const LiquidateHistory = loadable(() => import('../containers/jdhManagement/operationalHistoryList/liquidateHistoryList'));
const InsuredDiscount = loadable(() => import('../containers/jdhManagement/insuredDiscount'));
const PremiumManagement = loadable(() => import('../containers/jdhManagement/premiumManagement'));
const ContractManagement = loadable(() => import('../containers/jdhManagement/contractManagement'));
const FeeManagement = loadable(() => import('../containers/jdhManagement/feeManagement'));
//保证管理
const AcceptorManage = loadable(() => import('../containers/jdhManagement/acceptorManage/index'));
const ProductManagement = loadable(() => import('../containers/jdhManagement/productManagement/index'));
//保证人加保管理
const InsuranceManage = loadable(() => import('../containers/jdhManagement/insuranceManage'));
const InsuranceApply = loadable(() => import('../containers/jdhManagement/insuranceManage/component/insuranceApply'));
const InsuranceApplyTwo = loadable(() => import('../containers/jdhManagement/insuranceManage/component/insuranceApplyTwo'));
const InsuranceEndorseApply = loadable(() => import('../containers/jdhManagement/insuranceManage/component/insuranceEndorseApply'));
const InsuranceEndorseApplyAudit = loadable(() => import('../containers/jdhManagement/insuranceManage/component/insuranceEndorseApplyAudit'));
const InsuranceManageDetail = loadable(() => import('../containers/jdhManagement/insuranceManage/component/insuranceInfodetail'));

// 企业信息管理
const EnterpriseManage = loadable(() => import('../containers/supplyChainTicket/enterpriseManage/enterpriseManage'));
export default [
	{
		path: '/admin',
		name: 'App',
		component: App,
		children: [
			{
				path: '/admin/home',
				name: 'home',
				component: Home
			},
			{
				path: '/admin/WaitDealt',
				name: ' 待办',
				component: WaitDealt
			},
			//业务角色选择
			{
				redirect: '/admin',
				name: 'defaultRoute',
				component: '/admin/home'
			},
			//工作台
			//经办事项
			{
				path: '/admin/dealWith',
				name: '经办事项',
				component: DealWith
			},
			
			{
				path: '/admin/supplyChainTicket/:item',
				name: '供应链票据',
				component: SupplyChainTicket
			},
			{
				path: '/admin/jdhManagement/companyUpdate',
				name: '企业信息管理',
				component: EnterpriseManage
			},
			{
				path: '/admin/operatorManage/:item',
				name: '操作管理',
				component: OperatorManagement
			},
			//简单会
			{
				path: '/admin/jdhManagement/jsdendorse',
				name: '背书申请列表',
				component: JsdAudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseReply',
				name: '背书应答列表',
				component: JsdReply
			},
			{
				path: '/admin/jdhManagement/jsdendorseSearch',
				name: '背书数据查询',
				component: JsdSearch
			},
			{
				path: '/admin/jdhManagement/jsdendorseInfoEdit',
				name: '背书信息编辑',
				component: JsdInfoEdit
			},
			{
				path: '/admin/jdhManagement/jsdendorseApplyaudit',
				name: '背书申请审核',
				component: JsdApplyAudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseRevokeaudit',
				name: '背书撤销审核',
				component: JsdRevokeaudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseRevokeApplyAudit',
				name: '背书应答审核',
				component: JsdReplyApplyAudit
			},
			{
				path: '/admin/jdhManagement/jsdendorseApplyCheck',
				name: '背书申请核对',
				component: JsdApplyCheck
			},
			{
				path: '/admin/jdhManagement/jsdendorseRevoke',
				name: '背书申请撤销申请',
				component: JsdRevoke
			},
			{
				path: '/admin/jdhManagement/jsdendorseReplyApply',
				name: '背书应答申请',
				component: JsdReplyApply
			},
			{
				path: '/admin/jdhManagement/jsdendorseParInfo',
				name: '票面信息',
				component: JsdParInfo
			},
			{
				path: '/admin/jdhManagement/endorseGuar',
				name: '背书保证申请列表',
				component: EndorseGuaranteeList
			},
			{
				path: '/admin/jdhManagement/endorseGuarApply',
				name: '背书保证申请',
				component: EndorseGuaranteeApply
			},
			{
				path: '/admin/jdhManagement/endorseGuarCheck',
				name: '背书保证申请核对',
				component: EndorseGuaranteeCheck
			},
			{
				path: '/admin/jdhManagement/endorseGuarApplyAudit',
				name: '背书保证申请审核',
				component: EndorseGuaranteeApplyAudit
			},
			{
				path: '/admin/jdhManagement/endorseGuarRevocation',
				name: '背书保证申请撤销',
				component: EndorseGuaranteeRevocation
			},
			{
				path: '/admin/jdhManagement/endorseGuarRevocationAudit',
				name: '背书保证申请撤销审核',
				component: EndorseGuaranteeRevocationAudit
			},
			{
				path: '/admin/jdhManagement/endorseGuarResp',
				name: '背书保证应答列表',
				component: EndorseGuaranteeRespList
			},
			{
				path: '/admin/jdhManagement/endorseGuarRespApply',
				name: '背书保证应答申请',
				component: EndorseGuaranteeRespApply
			},
			{
				path: '/admin/jdhManagement/endorseGuarRespApplyAudit',
				name: '背书保证应答审核',
				component: EndorseGuaranteeRespApplyAudit
			},
			{
				path: '/admin/jdhManagement/endorseGuarHistory',
				name: '背书保证数据查询',
				component: EndorseGuaranteeHistoryList
			},
			{
				path: '/admin/jdhManagement/endorseGuarDetail',
				name: '背书保证票面详情',
				component: EndorseGuaranteeDetail
			},
			{
				path: '/admin/jdhManagement/platformInfo',
				name: '供应链平台信息管理',
				component: Platforminfo
			},
			{
				path: '/admin/jdhManagement/orgInfo',
				name: '供票参与机构信息管理',
				component: OrgInfo
			},
			{
				path: '/admin/jdhManagement/discountforAudit',
				name: '贴现申请审核接口',
				component: DiscountforAudit
			},
			{
				path: '/admin/jdhManagement/insuredDiscountAudit',
				name: '加保贴现申请审核',
				component: InsuredDiscountAudit
			},
			{
				path: '/admin/jdhManagement/getTicketReplyAudit',
				name: '收票应答申请审核',
				component: GetTicketReplyAudit
			},
			{
				path: '/admin/jdhManagement/getTicketPromptAudit',
				name: '提示收票申请审核',
				component: GetTicketPromptAudit
			},
			{
				path: '/admin/jdhManagement/acceptanceReplyAudit',
				name: '承兑应答申请审核',
				component: AcceptanceReplyAudit
			},
			{
				path: '/admin/jdhManagement/promptAcceptanceAudit',
				name: '提示承兑申请审核',
				component: PromptAcceptanceAudit
			},
			{
				path: '/admin/jdhManagement/tradeBackgroundAudit',
				name: '出票贸易背景审核',
				component: TradeBackgroundAudit
			},
			{
				path: '/admin/jdhManagement/drawerBillAudit',
				name: '出票审核',
				component: DrawerBill
			},
			{
				path: '/admin/jdhManagement/operatorManageModify',
				name: '操作员修改',
				component: OperatorManageModify
			},
			{
				path: '/admin/jdhManagement/operatorManageAdd',
				name: '操作员添加',
				component: OperatorManageAdd
			},
			{
				path: '/admin/jdhManagement/ticketRegistration',
				name: '电票登记',
				component: TicketRegistration
			},
			{
				path: '/admin/jdhManagement/compTicketRegistration',
				name: '电票登记-企业开通',
				component: CompTicketRegistration
			},
			{
				path: '/admin/jdhManagement/telexAccountManage',
				name: '电票账户管理',
				component: TelexAccountManage
			},
			{
				path: '/admin/jdhManagement/jsdAccountAdd',
				name: '企业账户添加',
				component: EnterpriseAccountAdd
			},
			{
				path: '/admin/jdhManagement/jsdRegistration',
				name: '企业账户登记',
				component: EnterpriseRegistration
			},
			{
				path: '/admin/jdhManagement/jsdCertification',
				name: '企业认证',
				component: JdhCertification
			},
			{
				path: '/admin/jdhManagement/jsdDeal',
				name: '企业认证',
				component: JsdDeal
			},
			// 企业开通对应页面
			{
				path: '/admin/jdhManagement/jsdCopmpCertification',
				name: '企业认证',
				component: JdhCompCertification
			},
			{
				path: '/admin/jdhManagement/jsdCompDeal',
				name: '企业认证',
				component: JsdCompDeal
			},
			{
				path: '/admin/pledge/list',
				name: '质押申请',
				component: PledgeList
			},
			{
				path: '/admin/pledge/pledgeInfoEdit',
				name: '质押申请信息编辑',
				component:PledgeInfoEdit
			},
			{
				path: '/admin/pledge/pledgeApplyCheck',
				name: '质押申请信息编辑核对',
				component:PledgeApplyCheck
			},
			{
				path: '/admin/pledge/pledgeApplyAudit',
				name: '质押申请审核',
				component:PledgeApplyAudit
			},
			{
				path: '/admin/pledge/pledgeApplyRevoke',
				name: '质押申请撤销',
				component:PledgeApplyRevoke
			},
			{
				path: '/admin/pledge/pledgeApplyRevokeAudit',
				name: '质押撤销审核',
				component:PledgeApplyRevokeAudit
			},
			{
				path: '/admin/pledge/pledgeDetail',
				name: '质押详情',
				component:PledgeDetail
			},
			{
				path: '/admin/pledge/pledgeApplyDetail',
				name: '质押应答详情',
				component:PledgeApplyDetail
			},
			{
				path: '/admin/pledge/pledgeAnswer',
				name: '质押申请应答',
				component:PledgeAnswer
			},
			{
				path: '/admin/pledge/pledgeAnswerApply',
				name: '质押申请应答申请',
				component:PledgeAnswerApply
			},
			{
				path: '/admin/pledge/pledgeAnswerApplyAudit',
				name: '质押申请应答审核',
				component:PledgeAnswerApplyAudit
			},
			{
				path: '/admin/draw/:item',
				name: '出票',
				component: DrawerIndex
			},
			{
				path: '/admin/discount/:item',
				name: '贴现',
				component: DiscountIndex
			},
			{
				path: '/admin/cash/:item',
				name: '兑付',
				component: CashIndex
			},
			{
				path: '/admin/pledge/:item',
				name: '质押',
				component: PledgeIndex
			},
			{
				path: '/admin/press/:item',
				name: '追索',
				component: PressIndex
			},
			{
				path: '/admin/successPage',
				name: '操作成功',
				component: SuccessPage
			},
			{
				path: '/admin/discountCost/:item',
				name: '贴现费用管理',
				component: DiscountCost
			},
			{
				path: '/admin/system/:item',
				name: '供票系统管理-部分页面',
				component: SystemManagement
			},
			// 供应链票据-运营人员
			{
				path: '/admin/jdhManagement/drawHistory',
				name: '出票数据查询（运营人员）',
				component: DrawHistoryList
			},
			{
				path: '/admin/jdhManagement/drawGuaranteeHistory',
				name: '出票保证数据查询（运营人员）',
				component: DrawGuaranteeHistoryList
			},
			{
				path: '/admin/jdhManagement/acceptGuaranteeHistory',
				name: '承兑保证数据查询（运营人员）',
				component: AcceptGuaranteeHistoryList
			},
			{
				path: '/admin/jdhManagement/endorseHistory',
				name: '背书数据查询（运营人员）',
				component: EndorseHistoryList
			},
			{
				path: '/admin/jdhManagement/endorseGuaranteeHistory',
				name: '背书保证数据查询（运营人员）',
				component: EndorseGuaranteeHistory
			},
			{
				path: '/admin/jdhManagement/discountHistory',
				name: '贴现数据查询（运营人员）',
				component: DiscountHistory
			},
			{
				path: '/admin/jdhManagement/pledgeHistory',
				name: '质押数据查询（运营人员）',
				component: PledgeHistory
			},
			{
				path: '/admin/jdhManagement/dischargeHistory',
				name: '质押解除数据查询（运营人员）',
				component: DischargeHistory
			},
			{
				path: '/admin/jdhManagement/acceptHistory',
				name: '承兑数据查询（运营人员）',
				component: AcceptHistory
			},
			{
				path: '/admin/jdhManagement/pressHistory',
				name: '追索数据查询（运营人员）',
				component: PressHistory
			},
			{
				path: '/admin/jdhManagement/liquidateHistory',
				name: '同意清偿数据查询（运营人员）',
				component: LiquidateHistory
			},
			//保证管理
			{
				path: '/admin/acceptorManage/:item',
				name: '保证产品承兑人管理',
				component: AcceptorManage
			},
			{
				path: '/admin/productManagement/:item',
				name: '保证产品管理',
				component: ProductManagement
			},
			//保证人加保管理
			{
				path: '/admin/insuredDiscount/detail',
				name: '保证人加保管理详情',
				component: InsuranceManageDetail
			},
			{
				path: '/admin/insuredDiscount/list',
				name: '保证人加保管理列表',
				component: InsuranceManage
			},
			{
				path: '/admin/insuredDiscount/apply',
				name: '保证人加保管理去审核',
				component: InsuranceApply
			},
			{
				path: '/admin/insuredDiscount/applya',
				name: '保证人加保管理审核',
				component: InsuranceApplyTwo
			},
			{
				path: '/admin/insuredDiscount/endorseApply',
				name: '背书保证应答审核',
				component: InsuranceEndorseApply
			},
			{
				path: '/admin/insuredDiscount/endorseApplyAudit',
				name: '加保应答',
				component: InsuranceEndorseApplyAudit
			},
			{
				path: '/admin/productManagement/:item',
				name: '保证产品管理',
				component: ProductManagement
			},
			{
				path: '/admin/insuredDiscount/:item',
				name: '加保贴现',
				component: InsuredDiscount
			},
			{
				path: '/admin/premiumManagement/:item',
				name: '加保-保费管理',
				component: PremiumManagement
			},
			{
				path: '/admin/contractManagement/:item',
				name: '合同管理-服务费、担保',
				component: ContractManagement
			},
			{
				path: '/admin/feeManagement/:item',
				name: '费用管理',
				component: FeeManagement
			},
			//账户管理
			{
				path: '/admin/accountManage/accountManage',
				name: '账户管理',
				component: AccountManage
			},
			{
				path: '/admin/accountManage/addqichannel',
				name: '企富通开通绑卡',
				component: Addqichannel
			},
			// //账户管理:绑卡/解绑卡小额校验
			{
				path: '/admin/accountManage/moneyaudit',
				name: '绑卡/解绑卡小额校验',
				component: Moneyaudit
			},
			//账户交易明细
			{
				path: '/admin/accountManage/TransactionDetails',
				name: '账户交易明细',
				component: TransactionDetails
			},
			{
				component: ErrorPage
			}
		]
	},
	//登录页
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/register',
		name: 'register',
		component: Register
	},
	{
		path: '/forgotPassword',
		name: 'forgotPassword',
		component: ForgotPassword
	},
	{
		path: '/smsCode',
		name: 'smsCode',
		component: SmsCode
	},
	{
		path: '/setDealPassword',
		name: 'setDealPassword',
		component: SetDealPassword
	},
	{
		path: '/businessRole/select',
		name: '业务角色选择',
		component: BusinessRoleSelect
	},
	{
		path: '/businessRoleJdh/select',
		name: '业务角色选择',
		component: BusinessRoleSelectJdh
	},
	{
		component: Login
	},
	{
		redirect: '/',
		name: 'defaultRoute',
		component: '/login'
	},
]